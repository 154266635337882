import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 
  allBrands : []
}

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    
    
    updateBrands: (state, action) => {
      state.allBrands = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateBrands } = brandSlice.actions

export default brandSlice.reducer