import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {
    
    Outlet,
  } from "react-router-dom";
import AccountNav from '../components/User/AccountNav';
function User() {
  return (
    <section className='space-at-top  all-back'>
      <div className='container'>
      <Row className="pb-2">
        <Col md={4}>
            
        </Col>
        <Col md={8}>
        
        </Col>
       
      </Row>
        <Row>
      
        <Col md={3} className="filter-main">
        <AccountNav/>
        </Col>
        <Col md={9} xs={12}>
           
           
             <Outlet />
           
        </Col>
      </Row>
      </div>
        
    </section>
  )
}

export default User