import React from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

import { getLocalData } from "../../utils/localStorage";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useTranslation } from "react-i18next";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

function UploadPres(props) {
  const { files, setFiles, setIsDisabled } = props;

  const { t } = useTranslation();

  return (
    <div className={`${files.length > 0 ? "contains" : "empty"}`}>
      
      <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={true}
        allowFileTypeValidation={true}
        acceptedFileTypes={["image/png", "image/jpeg"]}
        onupdatefiles={setFiles}
        onaddfilestart = {(file)=>{
         
          setIsDisabled(true)
        }}
        maxFiles={3}
        server={{
          process: {
            url: `${process.env.REACT_APP_API}/api/v1/Media/Upload/${process.env.REACT_APP_PHARMACY_ID}`,
            method: "POST",
            headers: {
              Authorization: `Bearer  ${getLocalData("user-info")?.userToken}`,
            },
            ondata: (formData) => {
           
              let fileAll = formData.getAll("filepond");

              var formData1 = new FormData();
              formData1.append("file", fileAll[1]);
             
           
              //formData.append('extraField', value);
              return formData1;
            },
            onload: (res) => {
              const obj = JSON.parse(res);
              setIsDisabled(false)
              return obj.response.pictureId;
            },
          },
        }}
        labelIdle={`${t("upc-t-1")} <span className="filepond--label-action">${t("upc-t-2")}</span> <br/> <span className="bel-upload">${t("upc-t-3")}<span>`} 
      />
    </div>
  );
}

export default UploadPres;
