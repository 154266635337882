import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  length: 0,
  allCategories : []
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    
    
    updateCategories: (state, action) => {
      state.allCategories = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateCategories } = categorySlice.actions

export default categorySlice.reducer