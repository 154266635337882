import React from 'react'
import { Row , Col} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ProductGridItem from "../components/ProductGridItem";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
function WishList() {
  

  const { t } = useTranslation();

  const  products  = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
 

  return (

    
    
    <section className='space-at-top'>
      <div className='container'>
      <Row className="pb-2">
        
        <Col md={12}>
        <span className="header-1 ms-2">
                <span
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </span>
                {t("wp-t-1")}
                
              </span>
        
        </Col>
       
      </Row>
        <Row>
      
        
        <Col md={12}>
           <Row>
           {
            products.filter((item)=> item.shoppingCartTypeId === 2)
            .map((product) => {
                  return product.product.published &&  <Col  lg={3} md={4} xs={6} key={product.product.id} className="even-spacer-y"><ProductGridItem imgUrl={product.product.pictureUrl}   name={product.product.name} brand={product.product.manufacturerName} oldPrice={product.product.oldPrice} price={product.product.price} id={product.product.id} /></Col>
                })
              }
           
            
           </Row>
        </Col>
      </Row>
      </div>
        
    </section>
  )
}

export default WishList