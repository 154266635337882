import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  alertState: 'success',
  alertMsg: 'msg',
  isEnabled: false
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    
    showAlert: (state, action) => {
      state.alertState = action.payload.state;
      state.alertMsg = action.payload.msg;
      state.isEnabled = true;

      
    },

    hideAlert(state){
      state.alertState = '';
      state.alertMsg = '';
      state.isEnabled = false;
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { showAlert, hideAlert } = alertSlice.actions

export default alertSlice.reducer