import axios from "axios";
import {deleteFromLocalData} from './localStorage'
import { updateUser } from "../redux/userSlice";
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API}api/v1/`,
});

export const setupInterceptors = (navigate, dispatch) => {
  axios.interceptors.request.use((request) => {
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
     
      return response;
    },
    (err) => {
      const { status } = err.response;

      if (status === 401) {
        // here we have access of the useHistory() from current Router
        console.log("unauthorized")
        navigate("/login");
        deleteFromLocalData('user-info');
        dispatch(updateUser({}));
      }

      return Promise.reject(err);
    }
  );
};

export default instance;
