import axios from "axios";
export const GetPharmacyDetails = async () => {
 
  const promise = axios.get(`${process.env.REACT_APP_API}api/v1/GetPharmacyDetails/${process.env.REACT_APP_PHARMACY_ID}`);

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


export const GetContactInformation = async () => {
 
  const promise = axios.get(`${process.env.REACT_APP_API}api/v1/Pharmacy/GetContactInformation/${process.env.REACT_APP_PHARMACY_ID}`);

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
