import React, {useEffect} from 'react'
import { Row , Col} from 'react-bootstrap'
import GeneralGridItem from '../components/GeneralGridItem'
import { getAllCategory } from "../services/CategoryService";


import { useSelector, useDispatch } from 'react-redux'
import { updateCategories } from '../redux/categorySlice'
import { useTranslation } from "react-i18next";

function AllCategories() {

  const  items  = useSelector((state) => state.category.allCategories);

  const { t } = useTranslation();
  const dispatch = useDispatch()
  
  

  useEffect(() => {
    getAllCategory(1, true).then(
      (res) => {
        dispatch(updateCategories(res))
        console.log(res)
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
   
  }, [dispatch])
  
  return (
    <section className='space-at-top allcategories-section'>
      <div className="container">
        <Row className="pb-2">
          <Col md={12} className="d-flex flex-column">
            <span className="header-1">{t("hc-t-2")}</span>
          </Col>
          
        </Row>
        
          <Row className="mobile-grid">
          {
                items.map((item) => {
                  return <GeneralGridItem imgUrl={item.imageURL} key={item.id} totalProducts={item.totalProducts}  name= {item.name}   id={item.id}  type="category" /> 
                })
              }
          </Row>
      </div>
    </section>
  )
}

export default AllCategories