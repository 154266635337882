import React from "react";
import NoImage from "../static/noimage.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function GeneralGridItemSlider(props) {
  const { name, totalProducts, imgUrl, type, id,dragging } = props;
  const {  t } = useTranslation();
  return (
    <>
      {totalProducts > 0 ? (
        <Link
        onClick={(e) => dragging && e.preventDefault()}
          to={`/products/${type === "brand" ? "brand" : "category"}/${id}`}
          className="gci gci2"
        >
          <div className="upperContent">
            <div>
            <img
              className="img-fluid-2"
              alt={name}
              src={imgUrl === "" ? NoImage : imgUrl}
            />
            </div>

            
          </div>
          <div className="lowerContent">
            <span className="item-name">{name}</span>
            <span className="item-count">
            <div>
                 <span className="ic-1">{totalProducts} {totalProducts > 1 ? t("gci-t-1") : t("gci-t-2")}</span>
              <span className="ic-2">Explore</span>
              </div>
              
            </span>
          </div>
        </Link>
      ) : (
        <div className="gci gci2 noClick">
          <div className="upperContent">
            <div>
            <img
              className="img-fluid-2"
              alt={name}
              src={imgUrl === "" ? NoImage : imgUrl}
            />
            </div>
            
          </div>
          <div className="lowerContent">
            <span className="item-name">{name}</span>
            <span className="item-count">
              {totalProducts} {totalProducts > 1 ? "Products" : "Product"}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default GeneralGridItemSlider;
