import React, {  useEffect } from "react";
import {  useDispatch } from 'react-redux'
import { hideAlert } from '../redux/alertSlice'


function Alert(props) {
  const { msg, state, isEnabled } = props;

  const dispatch = useDispatch()

  useEffect(() => {

    
    const timer = setTimeout(
      () => {dispatch(hideAlert())}, 
      3000
    );
   
    return () => clearTimeout(timer);
  }, [dispatch])
  
  return (
    <div className={`alert-parent ${isEnabled ? 'show-alert':'hide-alert'}`}>
      <div
        className={`alert alert-${state} alert-dismissible fade show`}
        role="alert"
      >
        {msg}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => {dispatch(hideAlert())
            
          }}
        ></button>
      </div>
    </div>
  );
}

export default Alert;
