import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  banners: [],
  categoriesWidget: [],
  brandsWidget: [],
  featuredProductWidget: [],
  offerProductWidget: [],
  popularTodayWidget: [],
  languageId : 1
}

export const homePageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    
    updateBanners: (state, action) => {
      state.banners = action.payload
    },
    updateCategoriesWidget: (state, action) => {
      state.categoriesWidget = action.payload
    },
    updateBrandsWidget: (state, action) => {
      state.brandsWidget = action.payload
    },
    updateFeaturedProductWidget: (state, action) => {
      state.featuredProductWidget = action.payload
    },
    updateOfferProductWidget: (state, action) => {
      state.offerProductWidget = action.payload
    },
    updatePopularTodayWidget: (state, action) => {
      state.popularTodayWidget = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateBanners,updateCategoriesWidget,updateOfferProductWidget, updateBrandsWidget , updateFeaturedProductWidget, updatePopularTodayWidget} = homePageSlice.actions

export default homePageSlice.reducer