import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ProductGridItem from "../ProductGridItem";
import { getNewProducts } from "../../services/ProductService";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { updatePopularTodayWidget } from "../../redux/homePageSlice";

function PopularTodayWidget() {
  const items = useSelector((state) => state.homepage.popularTodayWidget);
  const dispatch = useDispatch();

  useEffect(() => {
    getNewProducts(1, 4).then(
      (res) => {
        dispatch(updatePopularTodayWidget(res.data));
      },
      (failure) => {
        console.error(failure); //expected output: Oopsy...
      }
    );
  }, [dispatch]);

  return items.length > 0 ? (
    <section>
      <div className="container">
        <Row className="pb-2">
          <Col md={6} className="d-flex flex-column">
            <span className="header-1">Newly Added Products</span>
            <p className="sub-heading">Shop from selected Newly Added Products</p>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Link to={`/brands`} className="big-Link">
              SEE ALL Newly Added PRODUCTS
            </Link>
          </Col>
        </Row>
        <Row>
          {items.map((product) => {
            return (
              <Col lg={3} md={4} key={product.id} className="even-spacer-y">
                <ProductGridItem
                  imgUrl={product.pictureUrl}
                  name={product.name}
                  brand={product.manufacturerName}
                  oldPrice={product.oldPrice}
                  price={product.price}
                  id={product.id}
                  enableWishlist={true}
                  type={product.drugType}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  ) : (
    ""
  );
}

export default PopularTodayWidget;