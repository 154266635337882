import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  wishList: [],
  noOfProductsInWishlist: 0
}

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    
    addToWhishlist: (state, action) => {
      state.noOfProductsInWishlist += 1
      state.wishList.push(action.payload)
    },
    removefromWhishlist: (state, action) => {
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { addToWhishlist } = wishlistSlice.actions

export default wishlistSlice.reducer