import React from "react";

function CartBlack() {
  return (
    <>
     

    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<g clipPath="url(#clip0_746_13196)">
<path d="M9.48828 22C10.0406 22 10.4883 21.5523 10.4883 21C10.4883 20.4477 10.0406 20 9.48828 20C8.936 20 8.48828 20.4477 8.48828 21C8.48828 21.5523 8.936 22 9.48828 22Z" stroke="url(#svg-grad)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.4883 22C21.0406 22 21.4883 21.5523 21.4883 21C21.4883 20.4477 21.0406 20 20.4883 20C19.936 20 19.4883 20.4477 19.4883 21C19.4883 21.5523 19.936 22 20.4883 22Z" stroke="url(#svg-grad)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.48828 1H5.48828L8.16828 14.39C8.25973 14.8504 8.51019 15.264 8.87583 15.5583C9.24146 15.8526 9.69898 16.009 10.1683 16H19.8883C20.3576 16.009 20.8151 15.8526 21.1807 15.5583C21.5464 15.264 21.7968 14.8504 21.8883 14.39L23.4883 6H6.48828" stroke="url(#svg-grad)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<linearGradient  id="svg-grad" x1="8.48828" y1="22" x2="10.5732" y2="20.0929" gradientUnits="userSpaceOnUse">
<stop offset="0.0854" className="start-color" />
<stop offset="0.922" className="end-color"/>

        
</linearGradient>

<clipPath id="clip0_746_13196">
<rect width="24" height="24" fill="white" transform="translate(0.488281)"/>
</clipPath>
</defs>
</svg>
    </>
   
  );
}

export default CartBlack;
