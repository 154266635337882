import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  noOfProductsInCart: 0,
  noOfProductsInWishList: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addTOCart: (state, action) => {
      state.cartItems = [...action.payload];

      state.noOfProductsInCart = 0;
      state.noOfProductsInWishList = 0;
      action.payload.map((item) => {
        if (item.shoppingCartTypeId === 1) {
          state.noOfProductsInCart = state.noOfProductsInCart + item.quantity;
        } else if (item.shoppingCartTypeId === 2) {
          state.noOfProductsInWishList =
            state.noOfProductsInWishList + item.quantity;
        }
      });
    },
    updateQuantity: (state, action) => {
     
      

    },
  },
});

// Action creators are generated for each case reducer function
export const { addTOCart, updateQuantity } = cartSlice.actions;

export default cartSlice.reducer;
