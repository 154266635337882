import React from "react";

import error from "../../static/404.png";
import { useSelector } from "react-redux";
import PhoneSolidIcon from "../Icons/PhoneSolidIcon";
import MailSolidIcon from "../Icons/MailSolidIcon";

const ServiceExpired = () => {
  const pharmacy = useSelector(
    (state) => state.pharmacy.pharmacyDetails.pharmacy
  );

  function formatPhoneNumber(param) {
    const countryCode = param?.countryCode;
    const phoneNumber = param?.phoneNumber;

    const formattedNumber = `+${countryCode} ${phoneNumber.slice(
      0,
      3
    )}-${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`;

    return formattedNumber;
  }

  console.log(pharmacy);
  return (
    <div className="main_expired">
      <div className="expired">
        <div className="upper">
          <img src={error} alt="" />
          <h2>
            Sorry! <br />
            Service has been expired.{" "}
          </h2>
        </div>
        <div className="contect">
          <div className="subCon">
            <p>Please contact your pharmacy/hospital.</p>
            <div className="contect_info">
              <div className="info">
                <PhoneSolidIcon />
                <span>{formatPhoneNumber(pharmacy)}</span>
              </div>
              <div className="info">
                <MailSolidIcon />
                <span>{pharmacy?.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceExpired;
