import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  
  type: 0,
  isEnabled: false
}

export const searchModalSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    
    showModal: (state, action) => {
      state.type = action.payload;
      
      state.isEnabled = true;

      
    },

    hideModal(state){
      state.type = 0;
      state.isEnabled = false;
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { showModal, hideModal } = searchModalSlice.actions

export default searchModalSlice.reducer