import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  minPrice: 0,
  maxPrice: 0,
  brandId: 0,
  discount: 0,
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    
    
    updateMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    updateMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    updateBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    updateDiscount: (state, action) => {
      state.discount = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateMinPrice, updateMaxPrice, updateBrandId, updateDiscount } = filterSlice.actions

export default filterSlice.reducer