import axios from "axios";

const pharmacyId = process.env.REACT_APP_PHARMACY_ID;

export const getAllOutlets = async (
  

  patientId,
  lat,
  lon
  
) => {
  
  

  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetOutlets`,
    {
      pharmacyId,
      patientId,
      lat,
      lon

    }
  );

  const dataPromise = promise.then((response) =>  response.data.response.outlets);

 
  return dataPromise;
};


export const getNearByOutlets = async (
  

  patientId,
  lat,
  lon
  
) => {
  
  

  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetNearByOutlets`,
    {
      pharmacyId,
      patientId,
      lat,
      lon

    }
  );

  const dataPromise = promise.then((response) =>  response.data.response.outlets);

 
  return dataPromise;
};

