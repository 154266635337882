import axios from "axios";

const pharmacyId = process.env.REACT_APP_PHARMACY_ID;

export const addToCart = async (
  
  customerId,
  outletId,
  token,
  orderItems
) => {
  
  
 

  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1.1/AddToCart`,
    {
      customerId,
      outletId,
      pharmacyId,
      orderItems

    },{
     
        headers: {'Authorization': `Bearer  ${token}`}
    }
  );

  const dataPromise = promise.then((response) =>  response.data.response.message);

 
  return dataPromise;
};


export const getCartItems = async (
  token,
  patientId,
      outletId
) => {
  

 
  const promise = axios.post(
    `${process.env.REACT_APP_API}api/v1/GetCartItems?outletId=${outletId}`,
    { 
      pharmacyId,
      patientId,
      outletId
                    
    },{
     
        headers: {'Authorization': `Bearer  ${token}`}
    }
  );

  const dataPromise = promise.then((response) =>  response.data.response.data);

 
  return dataPromise;
};