import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  address: []
}

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    
    updateAddressInStore: (state, action) => {
      state.address = action.payload
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { updateAddressInStore } = addressSlice.actions

export default addressSlice.reducer