import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import AddressComp from "../components/Cart/AddressComp";
import DiscountComp from "../components/Cart/DiscountComp";

import ProductListItem from "../components/Cart/ProductListItem";
import { addTOCart } from "../redux/cartSlice";
import { getCartItems } from "../services/CartService";
import { createOrderFromCart, getOrderTotal } from "../services/OrderService";
import { getLocalData } from "../utils/localStorage";

import OrderPlaced from "../static/Product/orderplaced.svg";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadPres from "../components/UploadPrescription/UploadPres";

import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../utils/numberFormater";
import PaymentMethodComp from "../components/Cart/PaymentMethodComp";

function Cart() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const allAssociatedProducts = useSelector((state) => state.cart.cartItems);
  const userData = useSelector((state) => state.user.userData);
  const noOfProductsInCart = useSelector(
    (state) => state.cart.noOfProductsInCart
  );

  const dispatch = useDispatch();

  const [isLoaderOneActive, setIsLoaderOneActive] = useState(false)
  const [isLoaderTwoActive, setIsLoaderTwoActive] = useState(false)

  const [orderTotal, setOrderTotal] = useState(null);
  const [discountname, setdiscountname] = useState("");
  const [addressid, setaddressid] = useState(0);

  const patient = useSelector((state) => state.user)
  // console.log(patient)
  const avaiablePaymentMethods = useSelector((state) => state.pharmacy.payemntMethods)

  const [createOrderItems, setCreateOrderItems] = useState({
    customerId: 0,
    shippingAddressId: 0,
    billingAddressId: 0,
    outletId: null,
    patientName: null,
    prescriptionUrl: null,
    pictureId: 0,
    doctorName: null,
    orderByPrescription: false,
    shippingMethod: "",
    couponCode: "",
    hasPrescription: false,
    instruction: "",
    PaymentMethodSystemName:
      avaiablePaymentMethods?.length > 0 ?
        avaiablePaymentMethods[0].systemName
        : "",
    prescriptionImageIds: [],
    orderGuid: ""
  });

  useEffect(() => {
    if (avaiablePaymentMethods?.length > 0) {
      setCreateOrderItems((prevValue) => ({
        ...prevValue,
        PaymentMethodSystemName: avaiablePaymentMethods[0]?.systemName
      }));
    }
    console.log(avaiablePaymentMethods)
  }, [avaiablePaymentMethods]);

  const [shippingmethod, setshippingmethod] = useState("StorePickup");


  const outletName = useSelector((state) => state.outlet.selectOutObj.outletName);
  const outletIdd = useSelector((state) => state.outlet.selectOutObj.outletID);



  const [defSelectedOutlet, setDefSelectedOutlet] = useState({
    name: outletName,
    id: getLocalData("outlet-id")
  })


  useEffect(() => {

    setDefSelectedOutlet({
      id: outletIdd,
      name: outletName
    })

  }, [outletName])






  useEffect(() => {

    if (defSelectedOutlet.name && defSelectedOutlet.name !== '' && defSelectedOutlet.name !== null) {
      // console.log("yo6")
      getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, defSelectedOutlet.id).then(
        (res) => {
          dispatch(addTOCart(res));
        },
        (failure) => {
          console.error(failure); //expected output: Oopsy...
        }
      );
    }


  }, [defSelectedOutlet])



  //calcuate Order-Total

  const calculateOrderTotal = useCallback(() => {
    getOrderTotal(
      userData.id,
      getLocalData("user-info")?.userToken,
      shippingmethod,
      discountname
    )
      .then((data) => {
        console.log("orderDetails", data)
        setOrderTotal(data);
        setCreateOrderItems((prevValue) => ({
          ...prevValue,
          orderGuid: data.nextOrderGuid
        }));
      })
      .catch((err) => console.log(err));
  }, [shippingmethod, discountname, userData.id]);

  const calculateOrderTotalUnk = useCallback(() => {
    getOrderTotal(
      userData.id,
      getLocalData("user-info")?.userToken,
      shippingmethod,
      discountname
    )
      .then((data) => {
        setOrderTotal(data);
        if (data.discountAmountByCoupon === 0) {
          setdiscountname("");
        }
      })
      .catch((err) => console.log(err));
  }, [shippingmethod, discountname, userData.id]);

  useEffect(() => {
    calculateOrderTotalUnk();
    setCreateOrderItems((createOrderItems) => ({
      ...createOrderItems,
      customerId: userData.id,
      outletId: userData.outletId,
    }));
  }, [
    allAssociatedProducts,
    calculateOrderTotalUnk,
    userData.id,
    userData.outletId,
  ]);

  //runs when discount gets updated
  useEffect(() => {
    if (discountname !== "") {
      calculateOrderTotal();
    }

    setCreateOrderItems((createOrderItems) => ({
      ...createOrderItems,
      couponCode: discountname,
    }));
  }, [discountname, calculateOrderTotal]);

  //runs when shipping method gets updated
  useEffect(() => {
    calculateOrderTotal();

    setCreateOrderItems((createOrderItems) => ({
      ...createOrderItems,
      shippingMethod: shippingmethod,
      shippingAddressId: shippingmethod === "CashOnDelivery" ? addressid : 0,
      billingAddressId: shippingmethod === "CashOnDelivery" ? addressid : 0,
    }));
  }, [shippingmethod, addressid, calculateOrderTotal]);

  //is prescription required

  const [isPresImagesNeeded, setIsPresImagesNeeded] = useState(false);
  const [files, setFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  //create Order Function
  const createOrder = () => {

    const prescriptionProducts = allAssociatedProducts.filter(
      (item) =>
        item.product.drugTypeValue === "PrescriptionDrug" &&
        item.shoppingCartTypeId === 1
    );


    let finalOrderItems = {
      ...createOrderItems,
      customerId: getLocalData("user-info")?.userData.id,
      outletId: defSelectedOutlet.id
    };
    setCreateOrderItems({
      ...createOrderItems,
      customerId: getLocalData("user-info")?.userData.id,
    });

    if (
      createOrderItems.shippingMethod === "CashOnDelivery" &&
      createOrderItems.shippingAddressId === 0 &&
      createOrderItems.billingAddressId === 0
    ) {
      swal({
        icon: "error",
        text: t("cp-t-1"),
      });
    } else {
      if (prescriptionProducts.length > 0) {
        setIsPresImagesNeeded(true);
      } else {
        setIsLoaderOneActive(true)
        createOrderFromCart(
          finalOrderItems,
          getLocalData("user-info")?.userToken
        )
          .then((data) => {
            setIsLoaderOneActive(false)
            setOrderDetails(data.data.response.data);
            if (data.data.status === "Success") {
              setIsOrderPlaced(true);
              // console.log("yo7")
              getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, defSelectedOutlet.id).then(
                (res) => {
                  dispatch(addTOCart(res));
                },
                (failure) => {
                  console.error(failure); //expected output: Oopsy...
                }
              );
            }
          })
          .catch((err) => {
            setIsLoaderOneActive(false)
            console.log(err)
          });
      }
    }
  };

  //Order-Placed States
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);

  //detect windows width -- Start
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const orderWithPrescription = () => {
    setIsLoaderTwoActive(true)
    let pictureIdArr = [];
    files.forEach((file) => {
      pictureIdArr.push(file.serverId);
    });

    if (pictureIdArr.length > 0) {
      let finalOrderItems = {
        ...createOrderItems,
        hasPrescription: true,
        prescriptionImageIds: pictureIdArr,
        outletId: defSelectedOutlet.id
      };

      createOrderFromCart(finalOrderItems, getLocalData("user-info")?.userToken)
        .then((data) => {
          setOrderDetails(data.data.response.data);
          if (data.data.status === "Success") {
            setIsLoaderTwoActive(false)
            setIsOrderPlaced(true);
            setIsPresImagesNeeded(false);
            // console.log("yo8")
            getCartItems(getLocalData("user-info")?.userToken, getLocalData("user-info")?.userData?.id, defSelectedOutlet.id).then(
              (res) => {
                dispatch(addTOCart(res));
              },
              (failure) => {
                console.error(failure); //expected output: Oopsy...
              }
            );
          }
        })
        .catch((err) => {
          setIsLoaderTwoActive(false)
          swal({
            icon: "error",
            text: t("cp-t-2"),
          });
        });
    } else {
      swal({
        icon: "error",
        text: t("cp-t-3"),
      });
    }
  };

  //detect windows width -- End
  const pharmacy = useSelector((state) => state.pharmacy.pharmacyDetails.pharmacy)

  const isStock = (product) => {
    if (!pharmacy.isInventoryLiteEnabled && !pharmacy.isPosEnabled) {
      return true;
    } else {
      return product.stockQuantity > 0;
    }
  };

  //currency
  const currency = useSelector((state) => state.pharmacy.currencySymbol);

  return (
    <section className="space-at-top  all-back">
      {!isOrderPlaced && (
        <div className={`container ${isPresImagesNeeded ? "d-none" : ""}`}>
          <div className=" row">
            <div className="d-flex flex-column col-md-12">
              <span className="header-1 ms-2">
                <span
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </span>
                {t("cp-t-4")}
              </span>
            </div>
          </div>
          {parseInt(noOfProductsInCart) > 0 ? (
            <Row>
              {windowDimenion.winWidth < 787 && (
                <>
                  <Col md={12}>
                    <div className="os-parent-button">
                      {orderTotal !== null ? (
                        <button
                          onClick={() => createOrder()}
                          className="btn pd-btn pd-atc"
                        >

                          {t("cp-t-5")}  : {currency}{" "}
                          {
                            formatNumber(orderTotal.grandTotal)
                          }

                          {
                            isLoaderOneActive && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className="ms-3"
                              />
                            )
                          }
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="os-parent">
                      <PaymentMethodComp
                        createOrderItems={createOrderItems}
                        setCreateOrderItems={setCreateOrderItems}
                      />
                    </div>
                    {/* <div className="os-parent-pymentmode">
                      {t("cp-t-6")} :<span> {t("cp-t-7")}</span>
                    </div> */}
                  </Col>
                  <Col md={12}>
                    <div className="os-parent">
                      <AddressComp
                        setshippingmethod={setshippingmethod}
                        setaddressidtwo={setaddressid}

                        defSelectedOutlet={defSelectedOutlet}
                        setDefSelectedOutlet={setDefSelectedOutlet}
                      />
                    </div>
                  </Col>
                </>
              )}

              <Col xs={12} md={8}>
                {allAssociatedProducts.map((product) => {
                  if (product.shoppingCartTypeId === 1) {
                    return (
                      <ProductListItem
                        product={product.product}
                        quantity={product.quantity}
                        key={product.productId}
                        stockQuantity={isStock(product)}

                        outletId={defSelectedOutlet.id}
                      />
                    );
                  }
                  return false;
                })}
                <Row>
                  {orderTotal !== null ? (
                    <Col md={12} className="cart-details-subtotal">
                      {t("cp-t-8")}:{" "}
                      <span>
                        {currency}{" "}

                        {
                          formatNumber(orderTotal.subTotal)
                        }
                      </span>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <Row>
                  {windowDimenion.winWidth >= 787 && (
                    <>
                      <Col md={12}>
                        <div className="os-parent-button">
                          {orderTotal !== null ? (
                            <button
                              onClick={() => createOrder()}
                              className="btn pd-btn pd-atc"
                            >
                              {t("cp-t-5")}: {currency}
                              {
                                formatNumber(orderTotal.grandTotal)
                              }
                              {
                                isLoaderOneActive && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    className="ms-3"
                                  />
                                )
                              }

                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="os-parent">
                          <PaymentMethodComp
                            createOrderItems={createOrderItems}
                            setCreateOrderItems={setCreateOrderItems}
                          />
                        </div>
                        {/* <div className="os-parent-pymentmode">
                          {t("cp-t-6")}:<span>  {t("cp-t-7")}</span>
                        </div> */}
                      </Col>
                      <Col md={12}>
                        <div className="os-parent">
                          <AddressComp
                            setshippingmethod={setshippingmethod}
                            setaddressidtwo={setaddressid}

                            defSelectedOutlet={defSelectedOutlet}
                            setDefSelectedOutlet={setDefSelectedOutlet}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  <Col md={12}>
                    <div className="os-parent">
                      <DiscountComp
                        discountname={discountname}
                        setdiscountname={setdiscountname}
                        savedmoney={
                          orderTotal !== null
                            ? orderTotal.discountAmountByCoupon
                            : ""
                        }
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="os-parent">
                      {orderTotal !== null ? (
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col xs={12} md={12} className="os-header">
                                {t("cp-t-9")}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6} md={6} className="os-text">
                                {t("cp-t-8")}
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                {currency}{" "}
                                {
                                  formatNumber(orderTotal.subTotal)
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} className="os-text">
                                Item discount
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                -{currency}
                                {
                                  orderTotal.productDiscountAmount
                                    .toString()
                                    .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} className="os-text">
                                Product discount
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                -{currency}
                                {
                                  orderTotal.productDiscountAmount
                                    .toString()
                                    .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                }
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6} md={6} className="os-text">
                                {t("cp-t-10")}
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                {currency}{" "}
                                {
                                  formatNumber(orderTotal.taxTotal)
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} className="os-text">
                                {t("cp-t-11")}
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                -{currency}{" "}
                                {
                                  formatNumber(orderTotal.discountAmountByCoupon)
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} className="os-text">
                                {t("cp-t-12")}
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                {currency}{" "}
                                {
                                  formatNumber(orderTotal.shippingCharge)
                                }
                              </Col>
                            </Row>

                            <Row className="os-gt-parent">
                              <Col xs={6} md={6} className="os-text">
                                {t("cp-t-13")}
                              </Col>
                              <Col xs={6} md={6} className="os-numbers">
                                {currency}{" "}
                                {
                                  formatNumber(orderTotal.grandTotal)
                                }
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={12}>{t("cp-t-14")} </Col>
            </Row>
          )}
        </div>
      )}
      {!isOrderPlaced && (
        <div className={`container ${isPresImagesNeeded ? "" : "d-none"}`}>
          <div className=" row">
            <div className="d-flex flex-column col-md-12">
              <span className="header-1 ms-2">
                <span
                  onClick={() => {
                    setIsPresImagesNeeded(false);
                  }}
                >
                  <ArrowBackIcon />
                </span>
                {t("cp-t-15")}
              </span>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <UploadPres
                files={files}
                setFiles={setFiles}
                setIsDisabled={setIsDisabled}
              />
            </Col>
            <Col md={12} className="text-center">
              <span className="pd-manufacturer-name">
                {t("cp-t-16")}

              </span>
            </Col>
            <Col md={12}>
              <button
                className="btn pd-btn pd-atc"
                onClick={() => orderWithPrescription()}
                disabled={isDisabled ? "disabled" : ""}
              >
                {t("cp-t-17")}

                {
                  isLoaderTwoActive && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className="ms-3"
                    />
                  )
                }
              </button>
            </Col>
          </Row>
        </div>
      )}
      {isOrderPlaced && !isPresImagesNeeded && (
        <div className="container">
          <Row>
            <Col md={12} className="text-center">
              <img src={OrderPlaced} alt="order-placed" />
            </Col>
            <Col md={12} className="text-center order-placed-heading mt-5 ">
              {t("cp-t-18")}
            </Col>
            <Col md={12} className="text-center order-placed-date ">
              {t("cp-t-19")} {" "}
              {moment(orderDetails.createdOnUtc).format("MMM DD, YYYY h:mm A")}
            </Col>
            <Col md={12} className="text-center order-placed-text mb-1">
              {t("cp-t-20")}
            </Col>
            <Col md={12} className="text-center order-placed-no">
              {t("cp-t-21")} {orderDetails.id}
            </Col>
            <Col md={3} className="text-center"></Col>
            <Col md={3} className="text-center">
              <Link
                to={`/user/orderdetails/${orderDetails.id}`}
                className="btn pd-btn "
              >
                {t("cp-t-22")}
              </Link>
            </Col>
            <Col md={3} className="text-center">
              <Link to="/" className="btn pd-btn pd-atc">
                {t("cp-t-23")}
              </Link>
            </Col>
            <Col md={3} className="text-center"></Col>
          </Row>
        </div>
      )}
    </section>
  );
}

export default Cart;
