import React, {useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import GeneralGridItem from "../GeneralGridItemSlider";
import { getAllBrands } from "../../services/BrandService";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { updateBrandsWidget } from '../../redux/homePageSlice'
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function BrandWidget() {
  const { t } = useTranslation();


  const  items  = useSelector((state) => state.homepage.brandsWidget);
  const  languageId  = useSelector((state) => state.homepage.languageId);

  
  const dispatch = useDispatch()

  useEffect(() => {
    getAllBrands(languageId, 1, 10).then(
        (res) => {
          dispatch(updateBrandsWidget(res)) ;
        },
        (failure) => {
          console.error(failure); //expected output: Oopsy...
        }
      );
  }, [languageId, dispatch]);


  // slick settings
  let dragging = false;
  const settings = {
    dots: false,
    infinite: items.length > 6 ? true : false,

    slidesToShow: 6 ,
    slidesToScroll: 1,
    
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),

    autoplay: true,
    speed: 470,
    autoplaySpeed: 1020,
    responsive: [
      
      {
        breakpoint: 480,
        settings: {
          infinite: items.length > 2 ? true : false,
          centerPadding: "20px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="brand-sec-margin cat-widget">
      <div className="container cat-cont">
        <Row className="mt-1">
          <Col md={6} sm={6} xs={8} className="d-flex flex-column">
          <span className="home-head"> {t("hc-t-3")} {t("gen-t-1")}</span>
            
          </Col>
          <Col md={6} sm={6} xs={4} className="d-flex align-items-center justify-content-end">
          <Link to={`/brands`} className="big-Link">
              {t("gen-t-2")}
            </Link>
          </Col>
        </Row>
        <Row className="mobile-grid brand-widget">
          <Slider {...settings}>
            {items.map((item) => {
              return<GeneralGridItem dragging={dragging} imgUrl={ item.pictureUrl} key={item.id} totalProducts={item.totalProducts}  name= {item.name} id={item.id}  type="brand" />;
            })}
           </Slider>
        </Row>
      </div>
    </section>
    
    
  );
}

export default BrandWidget;
