import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getLocalData } from "../../utils/localStorage";
import {
  getAllOrders,
  getOrderDetailsById,
  getOrderTotalPrescriptionOrder,
  ReUploadPrescriptionImages,
  updatePrescriptionApproval,
} from "../../services/OrderService";
import { updateOrders } from "../../redux/orderSlice";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Outlet from "../../static/DropDown/outlet.svg";
import TopBar from "../OrderDetails/TopBar";
import UploadPres from "../UploadPrescription/UploadPres";
import ProductListItem from "../OrderDetails/ProductListItem";

import DiscountComp from "../OrderDetails/DiscountComp";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/numberFormater";

const initVAl = {
  id: 0,
  patientId: 0,
  patientName: null,
  patientPhone: null,
  patientEmail: null,
  pharmacyId: 0,
  pharmacyName: null,
  outletId: 2,
  outletName: "test phama",
  shippingAddress: null,
  billingAddress: null,
  subTotal: 17820,
  grandTotal: 17820,
  orderStatus: "Pending",
  paymentMethod: null,
  shippingMethod: "StorePickup",
  taxPercentage: 0,
  shippingCharge: 0,
  flatTaxAmount: 0,
  taxTotal: 0,
  discountAmountByCoupon: 0,
  createdOnUtc: null,
  modifiedOnUtc: null,
  orderItems: [],
  clName: null,
  createdOnUtcStr: null,
  createdOnUtcTimeStr: null,
};

function OrderDetails() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const orders = useSelector((state) => state.order.orders);
  const userData = useSelector((state) => state.user.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllOrders(userData.id, getLocalData("user-info")?.userToken)
      .then((data) => {
        dispatch(updateOrders(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch, userData.id]);
  const { id } = useParams();

  useEffect(() => {
    getOrderDetailsById(getLocalData("user-info")?.userToken, id)
      .then((response) => {
        // if (response.data.status === "Success") {
        //   let initialValues = response.data.response.data;
        //   setValues(initialValues);
        //   setIsValuesLoaded(true);
        // }
        console.log(response)
        setorder(response);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const [order, setorder] = useState(initVAl);
  const [isLoaded, setIsLoaded] = useState(false);

  //upload prescription

  const [isRxImageActive, setIsRxImageActive] = useState(false);

  const [files, setFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [uploadRxButtonLoader, setUploadRxButtonLoader] = useState(false);
  const reuploadPres = () => {
    setIsRxImageActive(true);
  };

  const uploadImages = () => {
    setUploadRxButtonLoader(true);
    setIsDisabled(true);
    let pictureIdArr = [];

    files.forEach((file) => {
      pictureIdArr.push(file.serverId);
    });

    ReUploadPrescriptionImages(
      order.id,
      pictureIdArr,
      getLocalData("user-info")?.userToken
    )
      .then((data) => {
        setorder(data.data.response);
        setUploadRxButtonLoader(false);
        setIsRxImageActive(false);
        setIsDisabled(false);
      })
      .catch((err) => {
        setUploadRxButtonLoader(false);

        setIsDisabled(false);
        setIsRxImageActive(false);
        console.log(err);
      });
  };

  //review order

  const [isOrderInReviewState, setIsOrderInReviewState] = useState(false);
  const reviewOrder = () => {
    setIsOrderInReviewState(!isOrderInReviewState);
  };

  //discount
  const [discountname, setdiscountname] = useState("");

  //order total
  const [orderTotal, setOrderTotal] = useState(null);

  //currency
  const currency = useSelector((state) => state.pharmacy.currencySymbol);


  //calcuate Order-Total

  const calculateOrderTotal = useCallback(() => {
    getOrderTotalPrescriptionOrder(
      order.id,
      getLocalData("user-info")?.userToken,

      discountname
    )
      .then((data) => {
        setOrderTotal(data);
        setorder((order) => ({
          ...order,
          discountAmountByCoupon: data.discountAmountByCoupon,
          grandTotal: data.grandTotal,
          subTotal: data.subTotal,
          taxTotal: data.taxTotal,
          shippingCharge: data.shippingCharge
        })
        )
      })
      .catch((err) => console.log(err));
  }, [discountname]);

  const calculateOrderTotal2 = useCallback(() => {
    getOrderTotalPrescriptionOrder(
      order.id,
      getLocalData("user-info")?.userToken,

      discountname
    )
      .then((data) => {
        setOrderTotal(data);
        setorder((order) => ({
          ...order,
          discountAmountByCoupon: data.discountAmountByCoupon,
          grandTotal: data.grandTotal,
          subTotal: data.subTotal,
          taxTotal: data.taxTotal,
          shippingCharge: data.shippingCharge,
          updatedPresItems: false
        }))
      })
      .catch((err) => console.log(err));
  }, [order, discountname]);


  //runs when discount gets updated
  useEffect(() => {
    if (discountname !== "") {
      calculateOrderTotal();
    }


  }, [discountname, calculateOrderTotal]);


  useEffect(() => {
    if (order.updatedPresItems) {
      calculateOrderTotal2();
    }


  }, [order, calculateOrderTotal2]);



  //approve
  const updateOrderApproval = (aproval) => {


    updatePrescriptionApproval(

      order.id,
      aproval,
      discountname,
      getLocalData("user-info")?.userToken,

    ).then((response) => {


      if (response.status === 'Success') {
        setorder(response.response);
        setIsOrderInReviewState(false)
      }

    })
      .catch((err) => {
        console.log(err);
      });
  }

  const returnSubtypeto3 = () => {
    if (order.orderStatus === 'Cancelled') {
      return 3
    } else if (order.orderPrescription.statusValue === 'Rejected') {
      return 2
    } else if (order.orderPrescription.statusValue === 'Pending') {
      return 0
    } else if (order.orderPrescription.statusValue === 'Accepted') {
      return 1
    }
  }


  return (
    isLoaded && (
      <Row>
        <Col md={12}>
          <span className="header-1 ms-1">
            <span
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
            </span>
            {t("ucod-t-1")}  : {order.id}
          </span>
        </Col>
        <Col md={12}>
          <div className="account-general">
            <Row className="order-det-row-m">
              <Col md={12}>
                <Row className="my-5">
                  <Col md={2}></Col>
                  <Col md={8}>
                    {!order.hasPrescription && !order.orderByPrescription && (
                      <div>
                        <TopBar
                          type={1}
                          activeStep={order.orderStatus}
                          subType={order.orderStatus === "Cancelled" ? 2 : 1}
                        />
                      </div>
                    )}
                    {order.hasPrescription && !order.orderByPrescription && (
                      <div>
                        <TopBar
                          type={2}
                          subType={returnSubtypeto3()}
                          activeStep={
                            order.orderPrescription.statusValue === "Accepted"
                              ? order.orderStatus
                              : order.orderPrescription.statusValue +
                              order.orderPrescription.status
                          }
                        />
                      </div>
                    )}
                    {order.hasPrescription && order.orderByPrescription && (
                      <div>
                        <TopBar
                          type={3}
                          subType={returnSubtypeto3()}
                          approvalStatusValue={
                            order.orderPrescription.approvalStatusValue
                          }
                          activeStep={
                            order.orderPrescription.statusValue === "Accepted"
                              ? order.orderStatus
                              : order.orderPrescription.statusValue +
                              order.orderPrescription.status
                          }
                        />
                      </div>
                    )}
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </Col>

              {!isOrderInReviewState && (
                <>
                  {isRxImageActive && (
                    <Col md={12}>
                      <UploadPres
                        files={files}
                        setFiles={setFiles}
                        setIsDisabled={setIsDisabled}
                      />
                      <div>
                        <button
                          disabled={isDisabled ? "disabled" : ""}
                          type="button"
                          onClick={() => uploadImages()}
                          className="btn btn-info address-form-button"
                        >
                          {t("ucod-t-2")}

                          {uploadRxButtonLoader && (
                            <Spinner
                              variant="black"
                              className="ms-2"
                              size="sm"
                              animation="border"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          )}
                        </button>
                      </div>
                    </Col>
                  )}

                  {!isRxImageActive && (
                    <>
                      <Col md={6}>
                        <Row>
                          {order.askForOrderApproval && (
                            <Col md={12} className="orderdetails-parent mb-3">
                              <Row>
                                <Col md={12} xs={12} className="od-flex mt-0">
                                  <span className="order-value">
                                    {t("ucod-t-3")}
                                    <br />
                                    <br />
                                    {t("ucod-t-4")}

                                  </span>
                                </Col>

                                <Col md={12} xs={12} className="od-flex">
                                  <button
                                    type="button"
                                    onClick={() => reviewOrder()}
                                    className="btn ecom-solid"
                                  >
                                    {t("ucod-t-5")}

                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          )}

                          {order.hasPrescription &&
                            order.orderPrescription.statusValue === "Rejected" &&
                            order.orderStatus !== "Cancelled" ? (
                            <Col md={12} className="orderdetails-parent mb-3">
                              <Row>
                                <Col md={12} xs={12} className="od-flex mt-0">
                                  <span className="pres-reupload-warning">
                                    {t("ucod-t-6")}

                                  </span>
                                </Col>

                                <Col md={12} xs={12} className="od-flex">
                                  {t("ucod-t-7")}
                                  <span className="order-value">
                                    {order.orderPrescription.adminNote}
                                  </span>
                                </Col>
                                <Col md={12} xs={12} className="od-flex">
                                  <button
                                    type="button"
                                    onClick={() => reuploadPres()}
                                    className="btn btn-info address-form-button"
                                  >
                                    {t("ucod-t-8")}

                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col md={12} className="orderdetails-parent mb-3">
                            <Row>
                              <Col md={7} xs={6} className="od-flex mt-0">
                                {t("ucod-t-9")}:
                                <span className="order-value">{order.id}</span>
                              </Col>
                              <Col md={5} xs={6} className="od-flex mt-0">
                                {t("ucod-t-10")} :
                                <span className="order-value">
                                  {moment(order.createdOnUtc).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </span>
                              </Col>
                              <Col md={12} xs={12} className="od-flex">
                                {t("ucod-t-11")}
                                <span className="order-value">
                                  {order.shippingMethod === "CashOnDelivery"
                                    ? t("ucod-t-12")
                                    : ""}
                                </span>
                              </Col>
                              <Col md={12} xs={12} className="od-flex">
                                {t("ucod-t-13")}
                                <div className="outlet-prent-order">
                                  <div className="outlet-prent-order-icon">
                                    <img alt="sdf" src={Outlet} />
                                    {order.outletId}
                                  </div>
                                  <div className="outlet-prent-order-name">
                                    {order.outlet.outletName}
                                    <span className="order-value">
                                      {order.outlet.address}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          {!order.askForOrderApproval && (
                            <Col md={12} className="orderdetails-parent">
                              {order.orderItems.length > 0
                                ? order.orderItems.map((item) => {
                                  return (
                                    <Row key={item.id} className="od-list">
                                      <Col
                                        md={2}
                                        xs={2}
                                        className="od-list-img"
                                      >
                                        <img
                                          src={item.product.pictureUrl}
                                          alt={item.product.name}
                                        />
                                      </Col>
                                      <Col
                                        md={7}
                                        xs={7}
                                        className="od-list-name"
                                      >
                                        {item.product.name}
                                      </Col>
                                      <Col md={1} xs={1}>
                                        <span className="od-list-qty">
                                          {item.quantity}
                                        </span>
                                      </Col>
                                      <Col
                                        md={2}
                                        xs={2}
                                        className="od-list-price"
                                      >
                                        {currency}
                                        {
                                          formatNumber(item.unitPrice)

                                        }
                                      </Col>
                                    </Row>
                                  );
                                })
                                : ""}
                              <Row>
                                <Col md={3}></Col>
                                <Col md={9}>
                                  <Row className="od-summ">
                                    <Col md={8} xs={8} className="od-sum-title">

                                      {t("ucod-t-14")}
                                    </Col>
                                    <Col md={4} xs={4} className="od-sum-value">
                                      {currency}
                                      {
                                        formatNumber(order.subTotal)
                                      }
                                    </Col>
                                  </Row>

                                  <Row className="od-summ">
                                    <Col md={8} xs={8} className="od-sum-title">
                                      {t("ucod-t-15")}

                                    </Col>
                                    <Col md={4} xs={4} className="od-sum-value">
                                      -{currency}
                                      {
                                        formatNumber(order.discountAmountByCoupon)

                                      }
                                    </Col>
                                  </Row>
                                  <Row className="od-summ">
                                    <Col md={8} xs={8} className="od-sum-title">

                                      {t("ucod-t-16")}
                                    </Col>
                                    <Col md={4} xs={4} className="od-sum-value">
                                      {currency}
                                      {
                                        formatNumber(order.shippingCharge)
                                      }
                                    </Col>
                                  </Row>
                                  <Row className="od-summ">
                                    <Col md={8} xs={8} className="od-sum-title">

                                      {t("ucod-t-17")}
                                    </Col>
                                    <Col md={4} xs={4} className="od-sum-value">
                                      {currency}
                                      {
                                        formatNumber(order.taxTotal)
                                      }
                                    </Col>
                                  </Row>
                                  <Row className="od-tot">
                                    <Col md={8} xs={8} className="od-tot-title">
                                      {t("ucod-t-18")}
                                    </Col>
                                    <Col md={4} xs={4} className="od-tot-value">
                                      {currency}
                                      {
                                        formatNumber(order.grandTotal)
                                      }
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </Col>

                      <Col md={6} className="order-det-mobi-col">
                        {order.hasPrescription ? (
                          <div className="order-details-address-div">
                            <span className="order-details-address-div-title">
                              {t("ucod-t-19")}
                            </span>
                            <span className=" order-pres-parent">
                              {order.orderPrescription.prescriptionImages.map(
                                (image) => {
                                  return (
                                    <div className="order-pres-image-parent" key={image.pictureId}>
                                      <img
                                        src={image.pictureUrl}
                                        alt={image.pictureId}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {order.shippingAddress ? (
                          <div className="order-details-address-div">
                            <span className="order-details-address-div-title">
                              {t("ucod-t-20")}

                            </span>
                            <span className="order-details-address-div-name">
                              {" "}
                              {order.shippingAddress.fullName}
                            </span>
                            <span className="order-details-address-div-address">
                              {" "}
                              {order.shippingAddress.address}{" "}
                              {order.shippingAddress.city.length > 0
                                ? `, ${order.shippingAddress.city}`
                                : ""}{" "}
                              {order.shippingAddress.state.length > 0
                                ? `, ${order.shippingAddress.state}`
                                : ""}
                              {order.shippingAddress.zipCode.length > 0
                                ? `, ${order.shippingAddress.zipCode}`
                                : ""}
                            </span>
                            <span className="order-details-address-div-phone">
                              {" "}
                              {t("ucod-t-21")} : {order.shippingAddress.mobileNumber}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {order.billingAddress ? (
                          <div className="order-details-address-div">
                            <span className="order-details-address-div-title">
                              {" "}
                              {t("ucod-t-22")}
                            </span>
                            <span className="order-details-address-div-name">
                              {" "}
                              {order.billingAddress.fullName}
                            </span>
                            <span className="order-details-address-div-address">
                              {" "}
                              {order.billingAddress.address}{" "}
                              {order.billingAddress.city.length > 0
                                ? `, ${order.billingAddress.city}`
                                : ""}{" "}
                              {order.billingAddress.state.length > 0
                                ? `, ${order.billingAddress.state}`
                                : ""}
                              {order.billingAddress.zipCode.length > 0
                                ? `, ${order.billingAddress.zipCode}`
                                : ""}
                            </span>
                            <span className="order-details-address-div-phone">
                              {" "}
                              {t("ucod-t-21")}  : {order.billingAddress.mobileNumber}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </>
                  )}
                </>
              )}
              {isOrderInReviewState && (
                <Row>
                  <Col md={12}>
                    <span className="header-3 ms-1">
                      <span
                        onClick={() => {
                          reviewOrder();
                        }}
                      >
                        <ArrowBackIcon />
                      </span>
                      {/* {t("ucod-t-23")}   */}
                    </span>
                  </Col>
                  <Col md={7}>
                    {order.orderItems.map((product) => {
                      return (
                        <ProductListItem
                          product={product.product}
                          quantity={product.quantity}
                          key={product.productId}
                          orderProductID={product.id}
                          setOrder={setorder}
                          order={order}
                          discountname={discountname}
                          setdiscountname={setdiscountname}
                        />
                      );
                    })}
                  </Col>
                  <Col md={5}>
                    <Row>
                      <Col md={12} className="d-flex">
                        <button className="btn ecom-outline" onClick={() => { updateOrderApproval(2) }}>NOT NEEDED</button>
                        <button className="btn ecom-solid ms-2" onClick={() => { updateOrderApproval(1) }}>APPROVE</button>
                      </Col>
                      <Col md={12}>
                        <div className="os-parent-pymentmode">
                          {t("ucod-t-24")}  :<span> {t("ucod-t-25")}</span>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="os-parent">
                          <DiscountComp
                            orderId={order.id}
                            discountname={discountname}
                            setdiscountname={setdiscountname}
                            savedmoney={
                              orderTotal !== null
                                ? orderTotal.discountAmountByCoupon
                                : ""
                            }
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="os-parent">
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col xs={12} md={12} className="os-header">
                                  {t("ucod-t-26")}
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("ucod-t-27")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {currency}
                                  {
                                    formatNumber(order.subTotal)
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("ucod-t-28")} ({order.taxPercentage}%)
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {currency}
                                  {
                                    formatNumber(order.taxTotal)
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("ucod-t-29")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  -{currency}
                                  {
                                    formatNumber(order.discountAmountByCoupon)
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} md={6} className="os-text">
                                  {t("ucod-t-30")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {currency}
                                  {
                                    formatNumber(order.shippingCharge)
                                  }
                                </Col>
                              </Row>

                              <Row className="os-gt-parent">
                                <Col xs={6} md={6} className="os-text">
                                  {t("ucod-t-31")}
                                </Col>
                                <Col xs={6} md={6} className="os-numbers">
                                  {currency}
                                  {
                                    formatNumber(order.grandTotal)
                                  }
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    )
  );
}

export default OrderDetails;
