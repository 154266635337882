import * as React from "react";
import { styled } from "@mui/material/styles";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useTranslation } from "react-i18next";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));



function TopBar(props) {


  const { t } = useTranslation();

  const stepType1_1 = [t("stepType1_1-t1"), t("stepType1_1-t2"), t("stepType1_1-t3")];
const stepType1_2 = [t("stepType1_2-t1"),t("stepType1_2-t2"),t("stepType1_2-t3")];


const stepType2_1 = [
  t("stepType2_1-t1"),
  t("stepType2_1-t2"),
  t("stepType2_1-t3"),
  t("stepType2_1-t4")
];
const stepType2_2 = [
  t("stepType2_2-t1"),
  t("stepType2_2-t2"),
  t("stepType2_2-t3"),
  t("stepType2_2-t4")
];
const stepType2_3 = [
  t("stepType2_3-t1"),
  t("stepType2_3-t2"),
  t("stepType2_3-t3"),
  t("stepType2_3-t4")
];
const stepType2_4 = [
  t("stepType2_4-t1"),
  t("stepType2_4-t2"),
  t("stepType2_4-t3"),
  t("stepType2_4-t4")
];
const stepType3_1 = [
  t("stepType3_1-t1"),
  t("stepType3_1-t2"),
  t("stepType3_1-t3"),
  t("stepType3_1-t4"),
  t("stepType3_1-t5")
];
const stepType3_2 = [
  t("stepType3_2-t1"),
  t("stepType3_2-t2"),
  t("stepType3_2-t3"),
  t("stepType3_2-t4"),
  t("stepType3_2-t5")


];
const stepType3_3 = [
  t("stepType3_3-t1"),
  t("stepType3_3-t2"),
  t("stepType3_3-t3"),
  t("stepType3_3-t4"),
  t("stepType3_3-t5")

];
const stepType3_4 = [
  t("stepType3_4-t1"),
  t("stepType3_4-t2"),
  t("stepType3_4-t3"),
  t("stepType3_4-t4"),
  t("stepType3_4-t5")

];

  const { type, activeStep, subType, approvalStatusValue } = props;

  const Tp1activeStep = (as) => {
    let tempVar = 0;
    if (as === "Pending" && as === "Cancelled")  {
      tempVar = 0;
    } else if (as === "Processed") {
      tempVar = 1;
    } else if (as === "Delivered") {
      tempVar = 2;
    }
    return tempVar;
  };

  const Tp2activeStep = (as) => {
    let tempVar = 0;
    if (as === "Pending0" || as === "Rejected2") {
      tempVar = 0;
    } else if (as === "Pending") {
      tempVar = 1;
    } else if (as === "Processed") {
      tempVar = 2;
    } else if (as === "Delivered") {
      tempVar = 3;
    }
    return tempVar;
  };

  const Tp3activeStep = (as) => {
    let tempVar = 0;
    if (as === "Pending0" || as === "Rejected2" || as === "Accepted1") {
      tempVar = 0;
    } else if (as === "Pending" && approvalStatusValue === 'Pending') {
      tempVar = 1;
    } else if (as === "Pending" && approvalStatusValue !== '') {
      tempVar = 2;
    } else if (as === "Processed") {
      tempVar = 3;
    } else if (as === "Delivered") {
      tempVar = 4;
    }
    return tempVar;
  };


  return (
    <>
      {type === 1 && subType ===1 && (
        <Stepper
          activeStep={Tp1activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepType1_1.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 1 && subType === 2 && (
        <Stepper
          activeStep={Tp1activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
          className="cancelled-step"
        >
          {stepType1_2.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 2 && subType === 0 && (
        <Stepper
          activeStep={Tp2activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepType2_1.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 2 && subType === 1 && (
        <Stepper
          activeStep={Tp2activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepType2_2.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 2 && subType === 2 && (
        <Stepper
          activeStep={Tp2activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector /> }
        >
          {stepType2_3.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 2 && subType === 3 && (
        <Stepper
          activeStep={Tp2activeStep(activeStep)}
          alternativeLabel
          className="cancelled-step"
          connector={<QontoConnector /> }
        >
          {stepType2_4.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {type === 3 && subType === 0 && (
        <Stepper
          activeStep={Tp3activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepType3_1.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 3 && subType === 1 && (
        <Stepper
          activeStep={Tp3activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepType3_2.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 3 && subType === 2 && (
        <Stepper
          activeStep={Tp3activeStep(activeStep)}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {stepType3_3.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {type === 3 && subType === 3 && (
        <Stepper
          activeStep={Tp3activeStep(activeStep)}
          alternativeLabel
          className="cancelled-step"
          connector={<QontoConnector />}
        >
          {stepType3_4.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </>
  );
}

export default TopBar;
