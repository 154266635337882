import React from "react";
import Col from "react-bootstrap/Col";
import NoImage from "../static/noimage.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function GeneralGridItem(props) {
  const { name, totalProducts, imgUrl, type, id } = props;
  const {  t } = useTranslation();


  return (
    <Col lg={2} sm={6} md={2} xs={6} className="even-spacer-y gac  fcol">
      {totalProducts > 0 ? (
        <Link
          to={`/products/${type === "brand" ? "brand" : "category"}/${id}`}
          className="gci"
        >
          <div className="upperContent">
            <div>
            <img
              className="img-fluid-2"
              alt={name}
              src={imgUrl === "" ? NoImage : imgUrl}
            />
            </div>

            
          </div>
          <div className="lowerContent">
            <span className="item-name">{name}</span>
            <span className="item-count">
              <div>
                 <span className="ic-1">{totalProducts} {totalProducts > 1 ? t("gci-t-1") : t("gci-t-2")}</span>
              <span className="ic-2">Explore</span>
              </div>
             
            
            </span>
          </div>
        </Link>
      ) : (
        <div className="gci noClick">
          <div className="upperContent">
            <div>
            <img
              className="img-fluid-2"
              alt={name}
              src={imgUrl === "" ? NoImage : imgUrl}
            />
            </div>
            
          </div>
          <div className="lowerContent">
            <span className="item-name">{name}</span>
            <span className="item-count">
              {totalProducts} {totalProducts > 1 ? t("gci-t-1") : t("gci-t-2")}
            </span>
          </div>
        </div>
      )}
    </Col>
  );
}

export default GeneralGridItem;
