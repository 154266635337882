// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCh4MP68dE6AXQSfkh2A2Y7pNAk4ju-0xY",
  authDomain: "digital-pharmacy-io.firebaseapp.com",
  projectId: "digital-pharmacy-io",
  storageBucket: "digital-pharmacy-io.appspot.com",
  messagingSenderId: "555935256797",
  appId: "1:555935256797:web:fd40f6a5297537b50b8b34",
  measurementId: "G-HXK9MLQ4SV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);