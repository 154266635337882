import { configureStore } from '@reduxjs/toolkit'
import homePageReducer from './homePageSlice'
import counterReducer from './counterSlice'
import alertReducer from './alertSlice'
import wishlistReducer from './wishlistSlice'
import categoryReducer from './categorySlice'
import brandReducer from './brandSlice'
import filterReducer from './filterSlice'
import userReducer from './userSlice'
import orderReducer from './orderSlice'
import addressReducer from './addressSlice'
import cartReducer from './cartSlice'
import outletReducer from './outletSlice'
import pharmacyReducer from './pharmacySlice'
import searchModalReducer from './searchModalSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    wishlist: wishlistReducer,
    homepage: homePageReducer,
    alert: alertReducer,
    category: categoryReducer,
    brand: brandReducer,
    filter: filterReducer,
    user: userReducer,
    order: orderReducer,
    address: addressReducer,
    cart: cartReducer,
    pharmacy: pharmacyReducer,
    outlet: outletReducer,
    searchModal: searchModalReducer
  },
})