import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 
  selectedOutlet : 0,

  outArray :[],
  selectOutObj : {
    outletName: '',
    autoLoad: true
  }
}

export const outletSlice = createSlice({
  name: 'outlet',
  initialState,
  reducers: {
    
    updateSelectedOutlet: (state, action) => {
      state.selectOutObj = action.payload;
    },

    updateOutletId: (state, action) => {
      state.selectedOutlet = action.payload;
    },

    updateOutletArray: (state, action) => {
      state.outArray = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateOutletId, updateOutletArray, updateSelectedOutlet } = outletSlice.actions

export default outletSlice.reducer