import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetPharmacyDetails, GetContactInformation } from '../services/PharmacyService'



export const getPharmacySettingRedux = createAsyncThunk(
  'pharmacy/getPharmacySettings',
  (payload, { dispatch, rejectWithValue }) => {

    try {
      const response = GetPharmacyDetails()
      return response // Return a value synchronously using Async-await
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }



  }
)

export const getContactInformationRedux = createAsyncThunk(
  'pharmacy/getContactInformation',
  (payload, { dispatch, rejectWithValue }) => {

    try {
      const response = GetContactInformation()
      return response // Return a value synchronously using Async-await
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }



  }
)



export const getPharmacyEcomSettingRedux = createAsyncThunk(
  'pharmacy/getPharmacySettings',
  (payload, { dispatch, rejectWithValue }) => {

    try {
      const response = GetPharmacyDetails()
      return response // Return a value synchronously using Async-await
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }



  }
)




const initialState = {
  currencySymbol: '',
  currencyDto: {},
  defaultOutlet: null,
  payemntMethods: [],
  address: '',
  countryCode: '',
  phoneNumber: '',
  pharmacyDetails: {

    pharmacy: {},
    isSuccess: false,
    loading: false,
    message: ''
  },
  contactInfo: {

    data: {},
    isSuccess: false,
    loading: false,
    message: ''
  },
  isRTLModeEnabled: false
}

export const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {

    updateRTLSettings: (state, action) => {
      state.isRTLModeEnabled = action.payload;
    },

  },
  extraReducers: {
    [getPharmacySettingRedux.pending]: (state, { payload }) => {
      state.pharmacyDetails.loading = true
    },
    [getPharmacySettingRedux.fulfilled]: (state, { payload }) => {

      console.log(payload)
      state.pharmacyDetails.pharmacy = payload.pharmacy;
      state.currencySymbol = payload.pharmacy.currencyDto.symbol;
      state.currencyDto = payload.pharmacy.currencyDto;
      state.defaultOutlet = payload.defaultOutlet;
      state.payemntMethods = payload.avaiablePaymentMethods;
      state.pharmacyDetails.loading = false;
      state.pharmacyDetails.isSuccess = true;

    },
    [getPharmacySettingRedux.rejected]: (state, { payload }) => {
      state.pharmacyDetails.message = payload;
      state.pharmacyDetails.loading = false;
      state.pharmacyDetails.isSuccess = false;
    },


    [getContactInformationRedux.pending]: (state, { payload }) => {
      state.contactInfo.loading = true
    },
    [getContactInformationRedux.fulfilled]: (state, { payload }) => {


      state.contactInfo.data = payload
      state.contactInfo.loading = false;
      state.contactInfo.isSuccess = true;

    },
    [getContactInformationRedux.rejected]: (state, { payload }) => {
      state.contactInfo.message = payload;
      state.contactInfo.loading = false;
      state.contactInfo.isSuccess = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateRTLSettings } = pharmacySlice.actions

export default pharmacySlice.reducer