import React from 'react';

const MailSolidIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.503 6.97725C1.54107 6.10852 1.91297 5.288 2.54119 4.68676C3.1694 4.08551 4.00543 3.74994 4.875 3.75H19.125C19.9946 3.74994 20.8306 4.08551 21.4588 4.68676C22.087 5.288 22.4589 6.10852 22.497 6.97725L21.39 7.59225L12 12.648L2.61 7.59225L1.503 6.97725ZM1.5 8.691V16.875C1.5 17.7701 1.85558 18.6285 2.48851 19.2615C3.12145 19.8944 3.97989 20.25 4.875 20.25H19.125C20.0201 20.25 20.8785 19.8944 21.5115 19.2615C22.1444 18.6285 22.5 17.7701 22.5 16.875V8.691L22.1055 8.91075L12.3555 14.1608C12.2462 14.2196 12.1241 14.2504 12 14.2504C11.8759 14.2504 11.7538 14.2196 11.6445 14.1608L1.8855 8.9055L1.5 8.691Z" fill="#263077" />
        </svg>
    );
};

export default MailSolidIcon;